<template>
    <div :class="{ 'modal fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"
        :id="request_success" tabindex="-1" role="dialog" :aria-labelledby="request_success" aria-hidden="true">
        <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title" id="modal-title-default">Request Success</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="requestSuccess">
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">User Message</label>
                            <input type="text" class="form-control" placeholder="User Message" v-model="message"
                                required />
                        </div>
                        <button type="submit" class="btn bg-gradient-success w-100 mb-0">Make Success</button>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="modalclose('toggelRequestSuccessModal')" type="button"
                        class="btn btn-link ml-auto mb-0" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div :class="{ 'modal-backdrop fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"></div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import swlalerts from "../../swlalerts";

export default {
    props: ["active", "txnID", "rrID", "reward", "uid"],
    data() {
        return {
            message: "Your " + this.reward.title + " Reward Request is Successfully Completed.",
        };
    },
    created() {
        this.message = "Your " + this.reward.title + " Reward Request is Successfully Completed.";
    },
    methods: {
        modalclose(funtext) {
            this.$emit(funtext);
        },
        async requestSuccess() {

            // Update the Reward Request Status in Reward Request Document

            const db = firebase.firestore();

            const rrRef = db.collection("rewardRequest").doc(this.rrID);

            await rrRef.update({
                status: "Success",
                message: this.message,
            });

            // Update the Reward Request Status in TXN Document
            const txnRef = db.collection("transaction").doc(this.txnID);
            await txnRef.update({
                status: "Success",
                message: this.message,
            });

            // Send User Notification
            const notiRef = db.collection("notification").doc();
            await notiRef.set({
                body: this.message,
                coin: this.reward.price,
                credit: false,
                icon: "rewardRequest",
                tap: {},
                time: firebase.firestore.FieldValue.serverTimestamp(),
                title: this.reward.title,
                uid: this.uid
            });

            swlalerts.swldone(this.message);

            this.modalclose("toggelRequestSuccessModal");

        }
    },
};
</script>