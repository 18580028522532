<template>
    <div class="row">
        <div class="col-7">
            <div class="card h-100">
                <div class="card-header pb-0 p-3">
                    <h6 class="mb-0">Add Item</h6>
                </div>
                <div class="card-body p-3">
                    <form @submit.prevent="saveItem">
                        <div class="row">

                            <div class="col-3">
                                <label>Icon</label>
                                <div class="mb-3">
                                    <select class="form-control" v-model="menuItem.type" required>
                                        <option value="item">Item</option>
                                        <option value="separator">Separator</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-9"></div>

                            <div class="col-12" v-if="menuItem.type == 'item'">
                                <div class="row">
                                    <div class="col-3">
                                        <label>Icon</label>
                                        <div class="mb-3">
                                            <select class="form-control" v-model="menuItem.icon" required>
                                                <option value="refer">Refer & Earn</option>
                                                <option value="tutorial">Tutorial</option>
                                                <option value="telegram">Telegram</option>
                                                <option value="platform">Platform</option>
                                                <option value="settings">Settings</option>
                                                <option value="about">About</option>
                                                <option value="doc">Doc</option>
                                                <option value="contact">Contact</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <label>Title</label>
                                        <div class="mb-3">
                                            <input type="text" class="form-control" placeholder="Title"
                                                v-model="menuItem.title" required />
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <label>Coin</label>
                                        <div class="mb-3">
                                            <input type="text" class="form-control" placeholder="Title"
                                                v-model.number="menuItem.coin" required />
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <label>Hide</label>
                                        <div class="mb-3">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" style="margin-left: 0;" type="checkbox"
                                                    v-model="menuItem.hide" />
                                            </div>
                                        </div>
                                    </div>
                                    <h6>Navigator</h6>

                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col">
                                                <label>Navigate</label>
                                                <div class="mb-3">
                                                    <select class="form-control" v-model="menuItem.navigate.navigation">
                                                        <option value>Select Option</option>
                                                        <option value="url">URL</option>
                                                        <option value="web">Web View</option>
                                                        <option value="screen">Screen</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div v-if="menuItem.navigate.navigation == 'url'" class="col">
                                                <label>URL</label>
                                                <div class="mb-3">
                                                    <input type="text" class="form-control" placeholder="URL"
                                                        v-model="menuItem.navigate.url" required />
                                                </div>
                                            </div>
                                            <div class="col" v-if="menuItem.navigate.navigation == 'web'">
                                                <label>Title</label>
                                                <div class="mb-3">
                                                    <input type="text" class="form-control" placeholder="Title"
                                                        v-model="menuItem.navigate.title" required />
                                                </div>
                                            </div>
                                            <div class="col" v-if="menuItem.navigate.navigation == 'web'">
                                                <label>URL</label>
                                                <div class="mb-3">
                                                    <input type="text" class="form-control" placeholder="URL"
                                                        v-model="menuItem.navigate.url" required />
                                                </div>
                                            </div>
                                            <div class="col" v-if="menuItem.navigate.navigation == 'screen'">
                                                <label>Screen Name</label>
                                                <div class="mb-3">
                                                    <select class="form-control" v-model="menuItem.navigate.screen"
                                                        required>
                                                        <option value>Select Screen</option>
                                                        <option value="home">Home</option>
                                                        <option value="match">Match</option>
                                                        <option value="platforms">Platforms</option>
                                                        <option value="settings">Settings</option>
                                                        <option value="refer">Refer</option>
                                                        <option value="appReview">App Review</option>
                                                        <option value="watchEarn">Watch & Earn</option>
                                                        <option value="tutorial">Tutorial</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div v-if="menuItem.navigate.screen == 'home'" class="col">
                                                <label>ID</label>
                                                <div class="mb-3">
                                                    <select class="form-control" v-model="menuItem.navigate.id"
                                                        required>
                                                        <option value>Select ID</option>
                                                        <option value="0">Home</option>
                                                        <option value="1">News</option>
                                                        <option value="2">Subscription</option>
                                                        <option value="3">Reward</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div v-if="menuItem.navigate.screen == 'home'" class="col">
                                                <label>Sub ID</label>
                                                <div class="mb-3">
                                                    <select class="form-control" v-model="menuItem.navigate.subid"
                                                        required>
                                                        <option value>Select Index</option>
                                                        <option value="0">First</option>
                                                        <option value="1">Secound</option>
                                                        <option value="2">Third</option>
                                                        <option value="3">Fourth</option>
                                                        <option value="4">Fifith</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div v-if="menuItem.navigate.screen == 'match'" class="col">
                                                <label>Match ID</label>
                                                <div class="mb-3">
                                                    <input type="text" class="form-control" placeholder="Match ID"
                                                        v-model="menuItem.navigate.id" required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>


                            <div class="col-12">
                                <div class="mb-3">
                                    <button type="submit" class="btn bg-gradient-info mb-0 w-100">
                                        <span>Add Now</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
        <div class="col-5">
            <div class="card h-100">
                <div class="card-header pb-0 p-3">
                    <h6 class="mb-0">Menu List</h6>
                </div>
                <div class="card-body p-3">

                    <div class="row" v-for="item in menuList">

                        <div class="col-12 mb-4" v-if="item.type == 'item'">
                            <div class="row">
                                <div class="col">
                                    <h6>{{ item.title }}</h6>
                                </div>
                                <div class="col-3 coins" v-if="item.coin != 0">
                                    <img src="@/assets/img/coin.svg" alt />
                                    <h5 class="warning">{{ item.coin }}</h5>
                                </div>
                                <div class="col-2">
                                    <a href="javascript:void(0)" @click="removeItem(item)"
                                        class="btn btn-outline-danger btn-sm mb-0 w-100">
                                        <span>D</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mb-4" v-if="item.type == 'separator'">
                            <div class="row">
                                <div class="col">
                                    <hr>
                                </div>
                                <div class="col-2">
                                    <a href="javascript:void(0)" @click="removeItem(item)"
                                        class="btn btn-outline-danger btn-sm mb-0 w-100">
                                        <span>D</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase";
import SwlAlerts from "../../swlalerts.js";
import database from "../../databasefuncation";

export default {
    async created() {
        await this.getData();
    },

    data() {
        return {
            menuList: [],
            menuItem: {
                navigate: {}
            },
        };
    },
    methods: {
        async getData() {

            var db = firebase.firestore();

            var path = db.collection("manage_app").doc("leftDrawer");

            var data = await database.getdoc(path);

            this.menuList = data.menuList;

        },

        async saveItem() {

            // if (this.menuItem.type == "separator") {
            //     delete this.menuItem.icon;
            //     delete this.menuItem.title;
            //     delete this.menuItem.coin;
            //     delete this.menuItem.hide;
            //     delete this.menuItem.navigate;
            // }

            var object = {
                menuList: firebase.firestore.FieldValue.arrayUnion(this.menuItem),
            };

            await database.updatedoc(object, "manage_app", "leftDrawer");

            await this.getData();

            SwlAlerts.success("Item Added Successfully");

        },

        async removeItem(item) {

            var object = {
                menuList: firebase.firestore.FieldValue.arrayRemove(item),
            };

            await database.updatedoc(object, "manage_app", "leftDrawer");

            await this.getData();

            SwlAlerts.success("Item Removed Successfully");

        },

    },
};
</script>

<style scoped>
.coins {
  display: flex;
  align-items: center;
  justify-content: end;
}
.coins img {
  margin: 0 4px 0 0;
  width: 24px;
}
.coins h5 {
  margin: 0;
  color: #ff9501;
}
</style>