<template>
  <div class="horizontal row">
    <div class="col-xl-4" v-for="(match, index) in matches" :key="(match, index)" @click="selectmatch(match)">
      <MatchCard :match="match" :index="index" :platforms="sport.platforms" :action="false"
        :selectedmatch="selectedmatch" />
    </div>
  </div>

  <div class="d-flex flex-row bd-highlight">
    <div class="p-2 bd-highlight w-100">
      <div class="row align-items-center" style="min-height: 70px">
        <div class="col">
          <h4>Match Players</h4>
        </div>
      </div>

      <div class="mb-4" style="min-height:400px;" v-for="(player, index) in players" :key="(player, index)">
        <PlayerCard :player="player" :index="index" @bottomp="bottomp" @deleteplayer="deleteplayer" />
      </div>
    </div>

    <div class="p-2 bd-highlight w-100" v-if="platforms.myteam11.status && matchidmt11">
      <div class="row align-items-center" style="min-height: 70px">
        <div class="col">
          <h4>MyTeam11 Players</h4>
        </div>
      </div>

      <draggable v-model="mt11players" group="myteam11" :key="mt11players" @start="drag = true" @end="drag = false"
        item-key="id">
        <template #item="{ element, index }">
          <div class="mb-4" style="min-height:400px;">
            <PlayerCardmt11 :player="element" :index="index" @bottommt11="bottommt11"
              @deleteplayermt11="deleteplayermt11" />
          </div>
        </template>
      </draggable>
    </div>

    <div class="p-2 bd-highlight w-100" v-if="platforms.my11circle.status && matchidm11c">
      <div class="row align-items-center" style="min-height: 70px">
        <div class="col">
          <h4>My11Circle Players</h4>
        </div>
      </div>

      <draggable v-model="m11cplayers" group="my11cricle" :key="m11cplayers" @start="drag = true" @end="drag = false"
        item-key="id">
        <template #item="{ element, index }">
          <div class="mb-4" style="min-height:400px;">
            <PlayerCardm11c :player="element" :index="index" @bottomm11c="bottomm11c"
              @deleteplayerm11c="deleteplayerm11c" />
          </div>
        </template>
      </draggable>
    </div>

    <div class="p-2 bd-highlight w-100" v-if="platforms.my11circle.status && matchidm11c">
      <div class="row align-items-center" style="min-height: 70px">
        <div class="col">
          <h4>Vision11 Players</h4>
        </div>
      </div>

      <draggable v-model="v11players" group="vision11" :key="v11players" @start="drag = true" @end="drag = false"
        item-key="id">
        <template #item="{ element, index }">
          <div class="mb-4" style="min-height:400px;">
            <PlayerCardv11 :player="element" :index="index" @bottomv11="bottomv11" @deleteplayerv11="deleteplayerv11" />
          </div>
        </template>
      </draggable>
    </div>


  </div>
  <div class="row">
    <div class="col-12">
      <button type="button" class="btn bg-gradient-success w-100 mb-0 mt-3" @click="syncplayers">Sync All
        Players</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import draggable from "vuedraggable";
import database from "../../databasefuncation";

import MatchCard from "./platfrom/match-card.vue";
import PlayerCard from "./platfrom/player-card.vue";
import PlayerCardmt11 from "./platfrom/player-card-mt11.vue";
import PlayerCardm11c from "./platfrom/player-card-my11c.vue";
import PlayerCardv11 from "./platfrom/player-card-v11.vue";

export default {
  props: ["sport"],
  components: {
    MatchCard,
    PlayerCard,
    draggable,
    PlayerCardmt11,
    PlayerCardm11c,
    PlayerCardv11,
  },
  data() {
    return {
      matches: [],
      players: [],
      mt11players: [],
      m11cplayers: [],
      v11players: [],
      platforms: {},
      selectedmatch: null,
      matchidmt11: true,
      matchidm11c: true,
      matchidv11: true,
    };
  },
  async created() {
    const db = firebase.firestore();

    this.sport.platforms.forEach((platform) => {
      this.platforms[platform.id] = platform;
    });

    var currentdate = firebase.firestore.Timestamp.now().toDate();

    var path = db
      .collection("matches")
      .where("sport", "==", this.sport.id)
      .where("time", ">", currentdate)
      .orderBy("time");

    this.matches = await database.getalldocs(path);
  },
  mounted() { },
  methods: {
    selectmatch(match) {
      this.selectedmatch = match;
      this.getplayers(match);
    },

    async getplayers(match) {
      const db = firebase.firestore();

      if (match.platforms.myteam11 == undefined) {
        this.matchidmt11 = false;
      } else {
        this.matchidmt11 = true;
      }

      if (match.platforms.my11circle == undefined) {
        this.matchidm11c = false;
      } else {
        this.matchidm11c = true;
      }

      var path = db.collection("matches_data").doc(match.id.toString());

      var matchdata = await database.getdoc(path);

      this.players = matchdata.players;



      for (const player of this.players) {
        player.sortName = this.convertName(player.name);
      }

      this.players.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      if (this.platforms.myteam11.status && match.platforms.myteam11 != undefined) {
        var teampmatches;

        var hostname =
          location.hostname === "localhost"
            ? "http://localhost:3000"
            : "https://proapi2.teammaster.in";

        await this.axios
          .get(
            `${hostname}/myteam11-players?matchid=${match.platforms.myteam11.id}`
          )
          .then((response) => {

            this.mt11players = response.data.data.players;

            for (const player of this.mt11players) {
              player.sortName = this.convertName(player.name);
            }

            this.mt11players.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });
          });

        for (const player of this.mt11players) {
          player.Role = await this.typeSendaer(
            "myteam11",
            this.sport.id,
            player.categoryId
          );
        }
      } else {
        this.mt11players = [];
      }

      if (this.platforms.my11circle.status && match.platforms.my11circle != undefined) {

        var hostname =
          location.hostname === "localhost"
            ? "http://localhost:3000"
            : "https://proapi2.teammaster.in";

        await this.axios
          .get(
            `${hostname}/my11circle-players?matchid=${match.platforms.my11circle.id}`
          )
          .then((response) => {
            this.m11cplayers = response.data;
          });

        for (const player of this.m11cplayers) {
          player.sortName = this.convertName(player.name);
        }

        this.m11cplayers.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        for (const player of this.m11cplayers) {
          player.roleName = await this.typeSendaer(
            "my11circle",
            this.sport.id,
            player.roleName
          );
        }
      } else {
        this.m11cplayers = [];
      }


      console.log({ "Vision": match.platforms.vision11 });
      if (this.platforms.vision11.status && match.platforms.vision11 != undefined) {
        var hostname =
          location.hostname === "localhost"
            ? "http://localhost:3000"
            : "https://proapi2.teammaster.in";

        console.log({ hostname });

        await this.axios
          .get(
            `${hostname}/vision11-players?matchid=${match.platforms.vision11.id}&sport=${this.platforms.vision11.sportid}`
          )
          .then((response) => {

            console.log(response.data.result)

            this.v11players = response.data.result;
          });

        for (const player of this.v11players) {
          player.sortName = this.convertName(player.name);
        }

        this.v11players.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        for (const player of this.v11players) {
          player.roleName = await this.typeSendaer(
            "vision11",
            this.sport.id,
            player.short_role
          );
        }
      } else {
        this.v11players = [];
      }

    },

    deleteplayer(index) {
      this.players.splice(index, 1);
    },

    deleteplayermt11(index) {
      this.mt11players.splice(index, 1);
    },

    deleteplayerm11c(index) {
      this.m11cplayers.splice(index, 1);
    },

    deleteplayerv11(index) {
      this.v11players.splice(index, 1);
    },

    bottomp(index) {
      this.players.push(this.players.splice(index, 1)[0]);
    },

    bottommt11(index) {
      this.mt11players.push(this.mt11players.splice(index, 1)[0]);
    },

    bottomm11c(index) {
      this.m11cplayers.push(this.m11cplayers.splice(index, 1)[0]);
    },

    bottomv11(index) {
      this.v11players.push(this.v11players.splice(index, 1)[0]);
    },

    deleteself() {
      this.$emit("deleteplayer", this.index);
    },

    async syncplayers() {
      for (const key in this.players) {
        if (
          this.platforms.myteam11.status &&
          this.selectedmatch.platforms.myteam11 != undefined &&
          this.mt11players[key] != null
        ) {
          this.players[key].platforms.myteam11 = {
            credits: this.mt11players[key].credits,
            id: this.mt11players[key].playerId,
            type: this.mt11players[key].Role,
          };
        }


        

        if (
          this.platforms.my11circle.status &&
          this.selectedmatch.platforms.my11circle != undefined &&
          this.m11cplayers[key] != null
        ) {
          this.players[key].platforms.my11circle = {
            credits: this.m11cplayers[key].credits,
            id: this.m11cplayers[key].id,
            type: this.m11cplayers[key].roleName,
          };
        }

        if (
          this.platforms.vision11.status &&
          this.selectedmatch.platforms.vision11 != undefined &&
          this.v11players[key] != null
        ) {

          console.log(this.v11players[key]);
          console.log(this.v11players[key].credit);
          this.players[key].platforms.vision11 = {
            credits: parseFloat(this.v11players[key].credit),
            id: this.v11players[key].id,
            type: this.v11players[key].roleName,
          };
        }


      }

      for (const key in this.players) {
        await database.updatedoc(
          {
            platforms: this.players[key].platforms,
          },
          "players",
          this.players[key].id.toString()
        );
      }

      await database.updatedoc(
        {
          players: this.players,
        },
        "matches_data",
        this.selectedmatch.id.toString()
      );

      await database.synccheck(this.selectedmatch.id.toString());
    },

    async typeSendaer(platform, sport, platformID) {
      if (platform == "myteam11") {
        if (sport == "cricket") {
          if (platformID == 2) {
            return "WK";
          } else if (platformID == 5) {
            return "BOWL";
          } else if (platformID == 4) {
            return "ALL";
          } else if (platformID == 3) {
            return "BAT";
          }
        } else if (sport == "football") {
          if (platformID == 2) {
            return "GK";
          } else if (platformID == 5) {
            return "MID";
          } else if (platformID == 4) {
            return "ST";
          } else if (platformID == 3) {
            return "DEF";
          }
        } else if (sport == "kabaddi") {
          if (platformID == 3) {
            return "DEF";
          } else if (platformID == 4) {
            return "ALL";
          } else if (platformID == 5) {
            return "RAI";
          }
        }
      }

      if (platform == "my11circle") {
        if (sport == "cricket") {
          if (platformID == "WICKET_KEEPER") {
            return "WK";
          } else if (platformID == "BOWLER") {
            return "BOWL";
          } else if (platformID == "ALL_ROUNDER") {
            return "ALL";
          } else if (platformID == "BATSMAN") {
            return "BAT";
          }
        } else if (sport == "football") {
          if (platformID == "GOAL_KEEPER_FOOTBALL") {
            return "GK";
          } else if (platformID == "MID_FIELDER_FOOTBALL") {
            return "MID";
          } else if (platformID == "FORWARD_FOOTBALL") {
            return "ST";
          } else if (platformID == "DEFENDER_FOOTBALL") {
            return "DEF";
          }
        } else if (sport == "kabaddi") {
          if (platformID == "DEFENDER_KABADDI") {
            return "DEF";
          } else if (platformID == "ALL_ROUNDER_KABADDI") {
            return "ALL";
          } else if (platformID == "RAIDER_KABADDI") {
            return "RAI";
          }
        }
      }

      if (platform == "vision11") {
        if (sport == "cricket") {
          if (platformID == "WK") {
            return "WK";
          } else if (platformID == "BOW") {
            return "BOWL";
          } else if (platformID == "AR") {
            return "ALL";
          } else if (platformID == "BAT") {
            return "BAT";
          }
        } else if (sport == "football") {
          if (platformID == "GOAL_KEEPER_FOOTBALL") {
            return "GK";
          } else if (platformID == "MID_FIELDER_FOOTBALL") {
            return "MID";
          } else if (platformID == "FORWARD_FOOTBALL") {
            return "ST";
          } else if (platformID == "DEFENDER_FOOTBALL") {
            return "DEF";
          }
        } else if (sport == "kabaddi") {
          if (platformID == "DEFENDER_KABADDI") {
            return "DEF";
          } else if (platformID == "ALL_ROUNDER_KABADDI") {
            return "ALL";
          } else if (platformID == "RAIDER_KABADDI") {
            return "RAI";
          }
        }
      }
    },

    convertName(fullName) {
      let parts = fullName.split(' '); // नाम को स्पेस से अलग करें
      if (parts.length === 0) return '';

      let formattedName = [];

      for (let i = 0; i < parts.length; i++) {
        if (i === parts.length - 1) {
          // आखिरी नाम को पूरा रखें
          formattedName.push(parts[i]);
        } else {
          // पहले और मिडल नाम के पहले अक्षर लें
          formattedName.push(parts[i][0]);
        }
      }

      return formattedName.join(' ');
    }

  },
};
</script>

<style scoped>
.horizontal.row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal.row .col-xl-4 {
  display: inline-block;
  float: none;
}
</style>