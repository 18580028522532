<template>
    <div v-for="request in requestlist" :key="request.id">
        <HowRequestCard :request="request" />
    </div>

    <div v-if="loading" class="loading-indicator">Loading...</div>
    <div ref="loadMore" class="load-more-trigger"></div>
</template>

<script>
import firebase from "firebase";
import HowRequestCard from "../../components/how-requests/How-Request-Card.vue";

export default {
    components: { HowRequestCard },
    data() {
        return {
            requestlist: [],
            loading: false,
            lastDoc: null,
        };
    },
    async created() {
        await this.getdata();
    },
    methods: {
        async getdata() {

            this.loading = true;

            const db = firebase.firestore();

            let query = db.collection("howRequest").orderBy("time", "desc").limit(20);

            if (this.lastDoc) {
                query = query.startAfter(this.lastDoc);
            }

            query.onSnapshot((snapshot) => {
                const newRequest = snapshot.docs.map(doc => ({
                    id: doc.id, // Include document ID here
                    ...doc.data(),
                }));

                if (snapshot.docs.length > 0) {
                    this.lastDoc = snapshot.docs[snapshot.docs.length - 1];
                }

                this.requestlist = newRequest; // Replace with new data for real-time updates
                this.loading = false;
            }, (error) => {
                console.error("Error listening to real-time updates:", error);
                this.loading = false;
            });
        },
    }
};
</script>