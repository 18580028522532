<template>
  <div class="row">
    <div class="col-xl-8 mb-3">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="savebanner">
            <div class="row">
              <div class="col-xl-6">
                <label>Image</label>
                <div class="mb-3">
                  <input type="file" class="form-control" placeholder="Banner Id" @change="previewImage"
                    accept="image/jpg" required />
                </div>
              </div>
              <div class="col-xl-6">
                <img class="img-fluid border-radius-lg preview" alt="Responsive image" :src="picture" />
              </div>
              <div class="col-xl-3">
                <label>Id</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Banner Id" v-model="id" v-bind:disabled="!isnew"
                    required />
                </div>
              </div>
              <div class="col-xl-3">
                <label>Position</label>
                <div class="mb-3">
                  <select class="form-control" v-model="position" required>
                    <option value>Select Option</option>
                    <option value="a">First</option>
                    <option value="b">Secound</option>
                    <option value="c">Third</option>
                    <option value="d">Fourth</option>
                    <option value="e">Fifth</option>
                    <option value="f">Sixth</option>
                  </select>
                </div>
              </div>
              <div class="col-xl-3">
                <label>Active</label>
                <div class="mt-1 mb-3">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" v-model="active" />
                  </div>
                </div>
              </div>

              <div class="col-xl-3">
                <label>Navigate</label>
                <div class="mb-3">
                  <select class="form-control" v-model="data.navigation">
                    <option value>Select Option</option>
                    <option value="url">URL</option>
                    <option value="web">Web View</option>
                    <option value="screen">Screen</option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="data.navigation == 'url'" class="row">
              <div class="col-xl-12">
                <label>URL</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="URL" v-model="data.url" required />
                </div>
              </div>
            </div>

            <div v-if="data.navigation == 'web'" class="row">
              <div class="col-xl-6">
                <label>Title</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Title" v-model="data.title" required />
                </div>
              </div>
              <div class="col-xl-6">
                <label>URL</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="URL" v-model="data.url" required />
                </div>
              </div>
            </div>

            <div v-if="data.navigation == 'screen'" class="row">
              <div class="col-xl-6">
                <label>Screen Name</label>
                <div class="mb-3">
                  <select class="form-control" v-model="data.screen" required>
                    <option value>Select Screen</option>
                    <option value="home">Home</option>
                    <option value="match">Match</option>
                    <option value="platforms">Platforms</option>
                    <option value="settings">Settings</option>
                    <option value="refer">Refer</option>
                    <option value="appReview">App Review</option>
                    <option value="watchEarn">Watch & Earn</option>
                  </select>
                </div>
              </div>

              <div v-if="data.screen == 'home'" class="col-xl-6">
                <label>ID</label>
                <div class="mb-3">
                  <select class="form-control" v-model="data.id" required>
                    <option value>Select ID</option>
                    <option value="0">Home</option>
                    <option value="1">News</option>
                    <option value="2">Subscription</option>
                    <option value="3">Reward</option>
                  </select>
                </div>
              </div>

              <div v-if="data.screen == 'home'" class="col-xl-6">
                <label>Sub Index</label>
                <div class="mb-3">
                  <select class="form-control" v-model="data.subid" required>
                    <option value>Select Index</option>
                    <option value="0">First</option>
                    <option value="1">Secound</option>
                    <option value="2">Third</option>
                    <option value="3">Fourth</option>
                    <option value="4">Fifith</option>
                  </select>
                </div>
              </div>

              <div v-if="data.screen == 'match'" class="col-xl-6">
                <label>Match ID</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Match ID" v-model="data.id" required />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-12">
                <button type="submit" class="btn bg-gradient-info mb-0">
                  <span v-if="isnew">Save Banner</span>
                  <span v-else>Update Banner</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <BannerPreview :id="id" :imageurl="imageurl" :active="active" :data="data" :position="position"
        :action="action" />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import SwlAlerts from "../../swlalerts.js";

import BannerPreview from "../../components/banners/Banner-Preview.vue";

export default {
  props: ["isnew"],
  components: { BannerPreview },

  created() {
    if (!this.isnew) {
      this.id = this.$route.params.id;

      const db = firebase.firestore();

      db.collection("banners")
        .doc(this.id)
        .get()
        .then((doc) => {
          var banner = doc.data();
          this.id = banner.id;
          this.active = banner.active;
          this.data = banner.data;
          this.position = banner.position;
          this.imageurl = banner.imageurl;
        })
        .catch((error) => {
          SwlAlerts.swlerror(error.message);
        });
    }
  },

  data() {
    return {
      imageData: null,
      picture: null,
      imageurl: "",
      id: null,
      active: true,
      position: "",
      action: false,
      data: {},
    };
  },
  methods: {
    previewImage(event) {
      this.picture = URL.createObjectURL(event.target.files[0]);
      this.imageData = event.target.files[0];
    },

    async uploadFile() {
      var imgurl;
      var user = firebase.auth().currentUser;
      await firebase
        .storage()
        .ref("/banners/" + user.uid + "/" + this.id + ".jpg")
        .put(this.imageData)
        .then(async (response) => {
          await response.ref
            .getDownloadURL()
            .then((downloadURL) => {
              imgurl = downloadURL;
            })
            .catch((err) => SwlAlerts.swlerror(err.message));
        });
      return imgurl;
    },

    async savebanner() {
      SwlAlerts.swlloading();

      this.imageurl = await this.uploadFile();

      const db = firebase.firestore();

      db.collection("banners")
        .doc(this.id)
        .set({
          id: this.id,
          imageurl: this.imageurl,
          active: this.active,
          data: this.data,
          position: this.position,
        })
        .then(() => {
          this.isnew
            ? SwlAlerts.swldone("Banner Successfully Added!")
            : SwlAlerts.swldone("Banner Successfully Updated!");
        })
        .catch((error) => {
          SwlAlerts.swlerror(error.message);
        });
    },
  },
};
</script>

<style scoped>
.form-switch .form-check-input,
.form-check .form-check-input {
  margin-left: 0 !important;
  float: none;
}
</style>