<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="updatehowearn">
            <div class="row">
              <div class="col-xl-10">
                <h2>Methods</h2>
              </div>
              <div class="col-xl-2" style="text-align: right;">
                <button type="button" class="btn bg-gradient-secondary w-100" @click="addmethod">Add Method</button>
              </div>
              <div class="col-xl-12">
                <div class="row" v-for="(method, index) in methods" :key="(method, index)">
                  <div class="col-xl-10">
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Title" v-model="method.title" required />
                    </div>
                  </div>
                  <div class=" col-xl-2">
                    <button type="button" class="btn bg-gradient-danger w-100" @click="deletemethod(index)">Delete
                      Method</button>
                  </div>
                  <div class="col-xl-2" style="text-align: right;">
                    <button type="button" class="btn bg-gradient-secondary w-100" @click="addTile(index)">Add
                      Tile</button>
                  </div>
                  <div class="col-xl-12">

                    <div class="row border mb-2 rounded" v-for="(tile, tileindex) in method.tiles"
                      :key="(tile, tileindex)">

                      <div class="col-4 mb-2">
                        <label for="">Title</label>
                        <input type="text" class="form-control" placeholder="Title" v-model="tile.title" required />
                      </div>

                      <div class="col-4 mb-2">
                        <label for="">Description</label>
                        <input type="text" class="form-control" placeholder="Description" v-model="tile.description"
                          required />
                      </div>


                      <div class="col-2">
                        <label>Active</label>
                        <div class="mt-1 mb-3">
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" v-model="tile.active" />
                          </div>
                        </div>
                      </div>

                      <div class="col-2" style="text-align: right;">
                        <label for="">Delete</label>
                        <button type="button" class="btn bg-gradient-danger w-100"
                          @click="deleteTile(index, tileindex)">Delete
                          Tile</button>
                      </div>

                      <div class="col-3 mb-2">
                        <label for="">Icon</label>
                        <input type="file" class="form-control" placeholder="Background Image"
                          @change="iconUpload(index, tileindex, $event)" accept="image/png" />
                      </div>

                      <div class="col-3 mb-2">
                        <label for="">Coins</label>
                        <input type="number" class="form-control" placeholder="Coins" v-model.number="tile.coins"
                          required />
                      </div>

                      <div class="col-3">
                        <label>Type</label>
                        <select class="form-control" v-model="tile.type">
                          <option value="single">Single Link</option>
                          <option value="multi">Multi Link</option>
                        </select>
                      </div>

                      <div class="col-3 mb-2">
                        <label for="">Key</label>
                        <input type="text" class="form-control" placeholder="Key For Matching" v-model="tile.key"
                          required />
                      </div>

                      <div v-if="tile.type == 'single'" class="col-12">
                        <div class="row">
                          <div class="col">
                            <label>Navigate</label>
                            <div class="mb-3">
                              <select class="form-control" v-model="tile.navigation.navigation">
                                <option value>Select Option</option>
                                <option value="url">URL</option>
                                <option value="web">Web View</option>
                                <option value="screen">Screen</option>
                              </select>
                            </div>
                          </div>
                          <div v-if="tile.navigation.navigation == 'url'" class="col">
                            <label>URL</label>
                            <div class="mb-3">
                              <input type="text" class="form-control" placeholder="URL" v-model="tile.navigation.url"
                                required />
                            </div>
                          </div>
                          <div class="col" v-if="tile.navigation.navigation == 'web'">
                            <label>Title</label>
                            <div class="mb-3">
                              <input type="text" class="form-control" placeholder="Title"
                                v-model="tile.navigation.title" required />
                            </div>
                          </div>
                          <div class="col" v-if="tile.navigation.navigation == 'web'">
                            <label>URL</label>
                            <div class="mb-3">
                              <input type="text" class="form-control" placeholder="URL" v-model="tile.navigation.url"
                                required />
                            </div>
                          </div>
                          <div class="col" v-if="tile.navigation.navigation == 'screen'">
                            <label>Screen Name</label>
                            <div class="mb-3">
                              <select class="form-control" v-model="tile.navigation.screen" required>
                                <option value>Select Screen</option>
                                <option value="home">Home</option>
                                <option value="match">Match</option>
                                <option value="platforms">Platforms</option>
                                <option value="settings">Settings</option>
                                <option value="refer">Refer</option>
                                <option value="appReview">App Review</option>
                                <option value="watchEarn">Watch & Earn</option>
                              </select>
                            </div>
                          </div>

                          <div v-if="tile.navigation.screen == 'home'" class="col">
                            <label>ID</label>
                            <div class="mb-3">
                              <select class="form-control" v-model="tile.navigation.id" required>
                                <option value>Select ID</option>
                                <option value="0">Home</option>
                                <option value="1">News</option>
                                <option value="2">Subscription</option>
                                <option value="3">Reward</option>
                              </select>
                            </div>
                          </div>

                          <div v-if="tile.navigation.screen == 'home'" class="col">
                            <label>Sub ID</label>
                            <div class="mb-3">
                              <select class="form-control" v-model="tile.navigation.subid" required>
                                <option value>Select Index</option>
                                <option value="0">First</option>
                                <option value="1">Secound</option>
                                <option value="2">Third</option>
                                <option value="3">Fourth</option>
                                <option value="4">Fifith</option>
                              </select>
                            </div>
                          </div>

                          <div v-if="tile.navigation.screen == 'match'" class="col">
                            <label>Match ID</label>
                            <div class="mb-3">
                              <input type="text" class="form-control" placeholder="Match ID"
                                v-model="tile.navigation.id" required />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="tile.type == 'multi'" class="col-12">
                        <div class="row">
                          <div class="col">
                            <label>How it work?</label>
                          </div>
                          <div class="col text-right">
                            <button type="button" class="btn btn-success btn-sm"
                              @click="addItemHow(index, tileindex)">Add</button>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-6" v-for="(how, howindex) in tile.hows" :key="(how, howindex)">
                            <div class="row">
                              <div class="col-xl-8">
                                <input type="text" class="form-control" placeholder="How" v-model="tile.hows[howindex]"
                                  required />
                              </div>
                              <div class="col-xl-4" style="text-align: right;">
                                <button type="button" class="btn bg-gradient-danger w-100"
                                  @click="deleteItemHow(index, tileindex, howindex)">Delete</button>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div class="row">
                          <div class="col">
                            <label>Buttons</label>
                          </div>
                          <div class="col text-right">
                            <button type="button" class="btn btn-success btn-sm"
                              @click="addItemButton(index, tileindex)">Add</button>
                          </div>
                        </div>

                        <div class="row mb-2" v-for="(buttondata, buttonindex) in tile.buttons"
                          :key="(buttondata, buttonindex)">

                          <div class="col">
                            <label for="">Title</label>
                            <input type="text" class="form-control" placeholder="Button Title"
                              v-model="tile.buttons[buttonindex].title" required />
                          </div>

                          <div class="col">
                            <label for="">Navigation Type</label>
                            <div class="mb-3">
                              <select class="form-control" v-model="tile.buttons[buttonindex].type">
                                <option value>Select Option</option>
                                <option value="navigation">Navigation</option>
                                <option value="dataRequest">Data Request</option>
                              </select>
                            </div>
                          </div>

                          <div class="col">
                            <label for="">Primery Button</label>
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" v-model="tile.buttons[buttonindex].main">
                            </div>
                          </div>

                          <div class="col-xl-2" style="text-align: right;">
                            <label for="">Delete</label>
                            <button type="button" class="btn bg-gradient-danger w-100"
                              @click="deleteItemButton(index, tileindex, buttonindex)">Delete</button>
                          </div>

                          <div class="col-12" v-if="tile.buttons[buttonindex].type == 'navigation'">
                            <div class="row">
                              <div class="col">
                                <label>Navigate</label>
                                <div class="mb-3">
                                  <select class="form-control"
                                    v-model="tile.buttons[buttonindex].navigation.navigation">
                                    <option value>Select Option</option>
                                    <option value="url">URL</option>
                                    <option value="web">Web View</option>
                                    <option value="screen">Screen</option>
                                  </select>
                                </div>
                              </div>
                              <div v-if="tile.buttons[buttonindex].navigation.navigation == 'url'" class="col">
                                <label>URL</label>
                                <div class="mb-3">
                                  <input type="text" class="form-control" placeholder="URL"
                                    v-model="tile.buttons[buttonindex].navigation.url" required />
                                </div>
                              </div>
                              <div class="col" v-if="tile.buttons[buttonindex].navigation.navigation == 'web'">
                                <label>Title</label>
                                <div class="mb-3">
                                  <input type="text" class="form-control" placeholder="Title"
                                    v-model="tile.buttons[buttonindex].navigation.title" required />
                                </div>
                              </div>
                              <div class="col" v-if="tile.buttons[buttonindex].navigation.navigation == 'web'">
                                <label>URL</label>
                                <div class="mb-3">
                                  <input type="text" class="form-control" placeholder="URL"
                                    v-model="tile.buttons[buttonindex].navigation.url" required />
                                </div>
                              </div>
                              <div class="col" v-if="tile.buttons[buttonindex].navigation.navigation == 'screen'">
                                <label>Screen Name</label>
                                <div class="mb-3">
                                  <select class="form-control" v-model="tile.buttons[buttonindex].navigation.screen"
                                    required>
                                    <option value>Select Screen</option>
                                    <option value="home">Home</option>
                                    <option value="match">Match</option>
                                    <option value="platforms">Platforms</option>
                                    <option value="settings">Settings</option>
                                    <option value="refer">Refer</option>
                                    <option value="appReview">App Review</option>
                                    <option value="watchEarn">Watch & Earn</option>
                                  </select>
                                </div>
                              </div>
                              <div v-if="tile.buttons[buttonindex].navigation.screen == 'home'" class="col">
                                <label>ID</label>
                                <div class="mb-3">
                                  <select class="form-control" v-model="tile.buttons[buttonindex].navigation.id"
                                    required>
                                    <option value>Select ID</option>
                                    <option value="0">Home</option>
                                    <option value="1">News</option>
                                    <option value="2">Subscription</option>
                                    <option value="3">Reward</option>
                                  </select>
                                </div>
                              </div>
                              <div v-if="tile.buttons[buttonindex].navigation.screen == 'home'" class="col">
                                <label>Sub ID</label>
                                <div class="mb-3">
                                  <select class="form-control" v-model="tile.buttons[buttonindex].navigation.subid"
                                    required>
                                    <option value>Select Index</option>
                                    <option value="0">First</option>
                                    <option value="1">Secound</option>
                                    <option value="2">Third</option>
                                    <option value="3">Fourth</option>
                                    <option value="4">Fifith</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12" v-if="tile.buttons[buttonindex].type == 'dataRequest'">
                            <div class="row">
                              <div class="col-6">
                                <div class="h6">Require Data</div>
                              </div>
                              <div class="col-6 text-right">
                                <button type="button" class="btn btn-success btn-sm"
                                  @click="dataRequest(index, tileindex, buttonindex)">Add</button>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12" v-for="(data, dataindex) in tile.buttons[buttonindex].dataRequest"
                                :key="(data, dataindex)">
                                <div class="row">
                                  <div class="col">
                                    <label for="">Title</label>
                                    <input type="text" class="form-control" placeholder="Title"
                                      v-model="tile.buttons[buttonindex].dataRequest[dataindex].title" required />
                                  </div>
                                  <div class="col">
                                    <label for="">Placeholder</label>
                                    <input type="text" class="form-control" placeholder="Placeholder"
                                      v-model="tile.buttons[buttonindex].dataRequest[dataindex].placeholder" required />
                                  </div>
                                  <div class="col">
                                    <label for="">Input Type</label>
                                    <select class="form-control"
                                      v-model="tile.buttons[buttonindex].dataRequest[dataindex].type">
                                      <option value="">Select Input Type</option>
                                      <option value="text">Text</option>
                                      <option value="number">Number</option>
                                      <option value="email">Email</option>
                                      <option value="file">File</option>
                                    </select>
                                  </div>
                                  <div v-if="tile.buttons[buttonindex].dataRequest[dataindex].type == 'file'" class="col">
                                    <label for="">File Type</label>
                                    <select class="form-control"
                                      v-model="tile.buttons[buttonindex].dataRequest[dataindex].fileType">
                                      <option value="">Select Input Type</option>
                                      <option value="image">Image</option>
                                      <option value="video">Video</option>
                                    </select>
                                  </div>
                                  <div class="col" style="text-align: right;">
                                    <label for="">Delete</label>
                                    <button type="button" class="btn bg-gradient-danger w-100"
                                      @click="dataRequestDelete(index, tileindex, buttonindex, dataindex)">Delete</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <hr>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2" style="text-align:right;">
                <button type="submit" class="btn bg-gradient-info mb-0 w-100">
                  <span>Update How Earn</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-4"></div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import { title } from "process";
import { type } from "os";

export default {
  data() {
    return {
      methods: [],
    };
  },

  async created() {
    var db = firebase.firestore();

    var path = db.collection("manage_app").doc("howearn");

    var howearn = await database.getdoc(path);

    this.methods = howearn.methods;
  },

  methods: {

    generateRandomName() {
      const timestamp = Date.now();
      const randomString = Math.random().toString(36).substring(2, 8);
      return `img_${timestamp}_${randomString}`;
    },

    async iconUpload(index, tileindex, event) {
      console.log(event.target.files[0]);
      console.log(index);
      console.log(tileindex);
      const randomName = this.generateRandomName();
      const imageName = `${randomName}.png`;
      this.methods[index].tiles[tileindex].icon = await this.uploadFile(event.target.files[0], imageName);
    },

    async uploadFile(imageUrl, name) {
      var imgurl;
      var user = firebase.auth().currentUser;
      await firebase
        .storage()
        .ref("/howearn/" + user.uid + "/" + name)
        .put(imageUrl)
        .then(async (response) => {
          await response.ref
            .getDownloadURL()
            .then((downloadURL) => {
              imgurl = downloadURL;
            })
            .catch((err) => SwlAlerts.swlerror(err.message));
        });
      return imgurl;
    },


    updatehowearn() {
      var object = {
        methods: this.methods,
      };

      database.updatedoc(object, "manage_app", "howearn");
    },
    addmethod() {
      console.log(this.methods);
      this.methods.push({
        title: "Method Title",
        tiles: []
      });
    },
    deletemethod(index) {
      this.methods.splice(index, 1);
    },
    addTile(index) {
      this.methods[index].tiles.push({
        title: index + "_Title",
        description: "",
        coins: 0,
        type: "single",
        active: true,
        navigation: {
        },
        hows: [],
        buttons: [],
      });
    },
    deleteTile(index, tileindex) {
      this.methods[index].tiles.splice(tileindex, 1);
    },

    addItemHow(index, tileindex) {
      this.methods[index].tiles[tileindex].hows.push("");
    },
    deleteItemHow(index, tileindex, howindex) {
      this.methods[index].tiles[tileindex].hows.splice(howindex, 1);
    },

    addItemButton(index, tileindex) {
      this.methods[index].tiles[tileindex].buttons.push({
        title: "",
        main: true,
        type: "navigation",
        navigation: {},
        dataRequest: [],
      });
    },
    deleteItemButton(index, tileindex, buttonindex) {
      this.methods[index].tiles[tileindex].buttons.splice(buttonindex, 1);
    },
    dataRequest(index, tileindex, buttonindex) {
      this.methods[index].tiles[tileindex].buttons[buttonindex].dataRequest.push({});
    },
    dataRequestDelete(index, tileindex, buttonindex, dataindex) {
      this.methods[index].tiles[tileindex].buttons[buttonindex].dataRequest.splice(dataindex, 1);
    },


  },
};
</script>

<style scoped>
.form-switch .form-check-input,
.form-check .form-check-input {
  margin-left: 0 !important;
  float: none;
}
</style>