<template>
    <div class="row">
        <div class="col-6 mb-4" v-for="pg in pgList">
            <div class="card">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-3">
                            <img class="border-radius-lg" :src="pg.icon" alt="">
                        </div>
                        <div class="col-9">
                            <h4>{{ pg.title }}</h4>
                            <p class="mb-0">{{ pg.description }}</p>
                            <p><small>Key : {{ pg.key }}</small></p>
                        </div>
                        <div class="col-4">
                            <label for="">Active</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" v-model="pg.active" />
                            </div>
                        </div>
                        <div class="col-4">
                            <label for="">Recommended Tag</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" v-model="pg.recommended" />
                            </div>
                        </div>
                        <div class="col-4">
                            <label for="">Selected</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" v-model="pg.selected" />
                            </div>
                        </div>
                        <div class="col-12" v-if="pg.key == 'web'">
                            <label for="">URL</label>
                            <input type="text" class="form-control" v-model="pg.url" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-2" style="text-align:right;">
            <a href="javascript:void(0)" @click="changeState" class="btn bg-gradient-info mb-0 w-100">
                <span>Update Payment</span>
            </a>
        </div>
    </div>
</template>


<script>
import firebase from "firebase";
import SwlAlerts from "../../swlalerts.js";
import database from "../../databasefuncation";

export default {
    async created() {
        await this.getData();
    },

    data() {
        return {
            pgList: [],
        };
    },
    methods: {
        async getData() {
            var db = firebase.firestore();

            var path = db.collection("manage_app").doc("paymentGateways");

            var data = await database.getdoc(path);

            this.pgList = data.pgList;
        },

        changeState() {
            SwlAlerts.swlloading();

            const db = firebase.firestore();

            db.collection("manage_app").doc("paymentGateways")
                .update({
                    pgList: this.pgList
                })
                .then(() => {
                    SwlAlerts.swldone("Payment Status Changed!");
                })
                .catch((error) => {
                    console.log(error);
                    SwlAlerts.swlerror(error.message);
                });
        },

    },
};
</script>

<style scoped>
.form-check .form-check-input {
    margin-left: 0;
}

img {
    max-width: 100%;
}
</style>