<template>
    <div class="card mt-3" v-if="!loading">
        <div class="card-body">
            <div class="row align-items-center">

                <div class="col">
                    <div class="d-flex px-2 py-1">
                        <div>
                            <img :src="user.photoURL" alt="" class="avatar avatar-sm me-3">
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-xs">{{ user.displayName }}</h6>
                            <p class="text-xs mt-1 mb-0">{{ user.email }}</p>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <p class="text-xs text-secondary mb-0">Time</p>
                    <h6 class="mt-1 text-xs">{{ timeright() }}</h6>
                </div>

                <div class="col">
                    <p class="text-xs text-secondary mb-0">Status</p>
                    <span v-if="request.status == 'inReview'" class="badge bg-gradient-warning">In Review</span>
                    <span v-if="request.status == 'success'" class="badge bg-gradient-success">Success</span>
                    <span v-if="request.status == 'failed'" class="badge bg-gradient-danger">Failed</span>
                </div>

                <div class="col">
                    <p class="text-xs text-secondary mb-0">Status</p>
                    <div class="row">
                        <!-- <div class="col" v-if="request.status != 'inReview'">
                            <button @click="toggelRequestDeleteModal"
                                class="btn btn-sm bg-gradient-warning w-100 mb-0">Panding</button>
                        </div> -->
                        <div class="col" v-if="request.status == 'inReview'">
                            <button class="btn btn-sm bg-gradient-success w-100 mb-0"
                                @click="toggelRequestSuccessModal">Success</button>
                        </div>
                        <div class="col" v-if=" request.status == 'inReview' ">
                            <button class="btn btn-sm btn-danger w-100 mb-0" @click="deleteRequest">Delete</button>
                        </div>
                    </div>
                </div>

            </div>
            <hr>
            <div class="row">
                <div class="col-6">
                    <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">How Request Details</h6>
                    <div class="row align-items-center">
                        <div class="col">
                            <div class="row">

                                <div class="col">
                                    <div class="d-flex px-2 py-1">
                                        <div class="mr-1">
                                            <img :src="request.icon" alt="" class="avatar me-3">
                                        </div>
                                        <div class="d-flex flex-column justify-content-center">
                                            <h6 class="mb-0 text-xs">{{ request.title }}</h6>
                                            <p class="text-xs mt-1 mb-0">{{ request.description }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <p class="text-xs text-secondary mb-0">Price</p>
                                    <h6 class="mt-1 text-xs"><img width="16" src="@/assets/img/coin.svg" alt /> {{
                                        request.coins }}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">Exrta Details</h6>
                    <div class="row">
                        <div class="col-6" v-for="(item, index) in request.data" :key="index">
                            <p class="text-xs text-secondary mb-1">{{ item.title }}</p>

                            <a href="javascript:;" v-if="item.type == 'file'"
                                @click="toggelRequestImageModal(item.value, item.title)">
                                <div class="p-5 border-radius-lg setImg"
                                    :style="{ backgroundImage: `url(${item.value})` }">
                                </div>
                            </a>
                            <h6 v-else class="mt-1 text-xs">{{ item.value }}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-2">User Message</h6>
            <div v-if="request.status == 'inReview'" class="alert alert-warning" role="alert">
                <strong>{{ request.status }}!</strong> {{ request.message }}
            </div>
            <div v-if="request.status == 'success'" class="alert alert-success" role="alert">
                <strong>{{ request.status }}!</strong> {{ request.message }}
            </div>
            <div v-if="request.status == 'failed'" class="alert alert-danger" role="alert">
                <strong>{{ request.status }}!</strong> {{ request.message }}
            </div>

        </div>
    </div>
    <RequestSuccessModal :active="requestSuccessModal" :howRequest="request" @toggelRequestSuccessModal="toggelRequestSuccessModal" />
    <RequestDeleteModal :active="requestDeleteModal" :howRequest="request" @toggelRequestDeleteModal="toggelRequestDeleteModal" />
    <RequestImageViewModal :active="requestImageViewModal" :image="currentImage" :title="currentTitle" @toggelRequestImageModal="toggelRequestImageModal" />
</template>

<script>





import firebase from "firebase";
import SwlAlerts from "../../swlalerts.js";
import TimeRight from "../../timeright.js";
import RequestSuccessModal from "./How-Request-Success.vue";
import RequestDeleteModal from "./How-Request-Delete.vue";
import RequestImageViewModal from "./How-Request-image-View.vue";


export default {
    props: ["request"],
    components: { RequestSuccessModal, RequestDeleteModal, RequestImageViewModal },
    data() {
        return {
            loading: true,
            requestSuccessModal: false,
            requestDeleteModal: false,
            requestImageViewModal: false,
            currentImage: "",
            currentTitle: "",
            user: {},
        };
    },
    created() {
        this.getuser();
    },
    methods: {
        timeright() {
            var time = TimeRight.datemodifiler(this.request.time);
            return time;
        },
        async getuser() {
            const db = firebase.firestore();

            db.collection("users").doc(this.request.uid).onSnapshot((doc) => {
                this.user = doc.data();
                console.log("User updated:", this.user);
                this.loading = false;
            }, (error) => {
                console.error("Error fetching user data:", error);
            });
        },
        async toggelRequestSuccessModal() {
            this.requestSuccessModal = !this.requestSuccessModal;
        },
        async toggelRequestDeleteModal() {
            this.requestDeleteModal = !this.requestDeleteModal;
        },
        async toggelRequestImageModal(image, title) {
            console.log(image);
            this.currentImage = image;
            this.currentTitle = title;
            this.requestImageViewModal = !this.requestImageViewModal;
        },
        async deleteRequest(){
            console.log("Hello");
            const db = firebase.firestore();
            const rrRef = db.collection("howRequest").doc(this.request.id);
            await rrRef.delete();
            SwlAlerts.swldone("Deleted");
        }
    },
};
</script>

<style scoped>
.setImg {
    background-position: center center;
    background-size: cover;
}
</style>