<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="bodymain nodatacard" v-if="match.empty">
        <span class="text">Blank Card</span>
      </div>
      <div class="bodymain" v-else>
        <div class="row align-items-center">
          <div class="col-7">
            <h6 style="margin:0;">{{ match.name }}</h6>
          </div>
           
          <div class="col-5">
            <p style="margin:0; text-align:right;">{{ timeright() }}</p>
          </div>
        </div>
        <div class="row teamdata align-items-center">
          <div class="col left">
            <span class="colorhint" :style="{ 'background-color':  match.team1_color }"></span>
            <span class="tshirt">
              <Tshirt :color="match.team1_color" />
            </span>
            <span class="teamname" :style="{ color:match.team1_color }">{{ match.team1display }}</span>
          </div>
          <div class="col center" style="text-align:center">
            <span class="timer">{{ timeerdata() }}</span>
          </div>
          <div class="col right">
            <span class="teamname" :style="{ color:match.team2_color }">{{ match.team2display }}</span>
            <span class="tshirt">
              <Tshirt :color="match.team2_color" />
            </span>
            <span class="colorhint" :style="{ 'background-color':match.team2_color }"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button
            type="button"
            class="btn bg-gradient-danger w-100 mb-0 mt-3"
            @click="deleteself"
          >Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

import SwlAlerts from "../../../swlalerts.js";
import TimeRight from "../../../timeright.js";
import Tshirt from "../../teams/Tshirt.vue";

export default {
  components: { Tshirt },
  props: ["match", "index"],
  async created() {
    
  },
  methods: {
    deleteself() {
      this.$emit("deletevison11ematch", this.index);
    },
    timeright() {
      var gettime = new Date(this.match.StartDate);

      var currenttime = firebase.firestore.Timestamp.fromDate(gettime);
      var time = TimeRight.datemodifiler(currenttime);

      return time;
    },

    timeerdata() {
      var gettime = new Date(this.match.StartDate);
      var currenttime = firebase.firestore.Timestamp.fromDate(gettime);
      var time = TimeRight.datetimer(currenttime);

      return time;
    },

    dateformater(datedate) {
      return (
        datedate.split(" ")[0].split("-")[1] +
        "-" +
        datedate.split(" ")[0].split("-")[0] +
        "-" +
        datedate.split(" ")[0].split("-")[2] +
        " " +
        datedate.split(" ")[1]
      );
    },
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
}
.teamdata .col {
  display: flex;
  position: relative;
  align-items: center;
}
.teamdata .col.right {
  justify-content: flex-end;
}

.teamdata .colorhint {
  width: 4px;
  height: 24px;
  display: block;
  background-color: gray;
  position: absolute;
  top: calc(50% - 12px);
}
.teamdata .left .colorhint {
  left: -12px;
  border-radius: 0 4px 4px 0;
}
.teamdata .right .colorhint {
  right: -12px;
  border-radius: 4px 0 0 4px;
}
.teamdata .col.center {
  justify-content: center;
}

.teamdata .left .tshirt {
  margin-right: 8px;
}

.teamdata .right .tshirt {
  margin-left: 8px;
}

.teamdata .tshirt {
  width: 64px;
  display: block;
}
.teamdata span.teamname {
  font-size: 18px;
  font-weight: bold;
}

.teamdata span.timer {
  font-size: 16px;
  font-weight: bold;
  color: #22292b;
}
.nodatacard{
  border: 2px dashed #ccc;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #ccc;
    font-size: 20px;
    padding: 100px;
    border-radius: 10px;
}
</style>