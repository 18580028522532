<template>
  <div class="row">
    <div class="col-xl-8 mb-4">
      <div class="card">
        <div class="card-body p-3">
          <form @submit.prevent="savesport">
            <div class="row mb-1">
              <div class="col-xl-3">
                <label>Id</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Sport Id" v-model="id" v-bind:disabled="!isnew"
                    required />
                </div>
              </div>
              <div class="col-xl-3">
                <label>Name</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Sport Name" v-model="name" required />
                </div>
              </div>
              <div class="col-xl-3">
                <label>Color</label>
                <div class="mb-3">
                  <input style="height : 40px" type="color" class="form-control" placeholder="Sport Color"
                    v-model="color" required />
                </div>
              </div>
              <div class="col-xl-3">
                <label>Status</label>
                <div class="mt-1 mb-3">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" v-model="status" />
                  </div>
                </div>
              </div>
              <div class="col-xl-2">
                <label>Position</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Sport Position" v-model.number="position"
                    required />
                </div>
              </div>
              <div class="col-xl-2">
                <label>Total Player</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Team Total Player" v-model.number="total"
                    required />
                </div>
              </div>
              <div class="col-xl-2">
                <label>Max Player</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Maximum Player in One Team" v-model.number="max"
                    required />
                </div>
              </div>
              <div class="col-xl-2">
                <label>Min Player</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Minimum Player in One Team" v-model.number="min"
                    required />
                </div>
              </div>


              <div class="col-xl-2">
                <label>CC</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Captin ID" v-model.number="cc" required />
                </div>
              </div>


              <div class="col-xl-2">
                <label>VC</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Vice-Captin ID" v-model.number="vc" required />
                </div>
              </div>


            </div>
            <hr />
            <div class="row" v-for="(type, index) in types" :key="(type, index)">
              <div class="col-xl-2">
                <div class="mb-2">
                  <input type="text" class="form-control" placeholder="Type Id" v-model.number="type.id" required />
                </div>
              </div>
              <div class="col-xl-2">
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Type Name" v-model="type.name" required />
                </div>
              </div>
              <div class="col-xl-2">
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Type Sort Name" v-model="type.sortname"
                    required />
                </div>
              </div>
              <div class="col-xl-2">
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Type Min" v-model.number="type.min" required />
                </div>
              </div>
              <div class="col-xl-2">
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Type Max" v-model.number="type.max" required />
                </div>
              </div>
              <div class="col-xl-2">
                <button type="button" class="btn bg-gradient-danger w-100" @click="deletetype(index)">Delete</button>
              </div>
            </div>
            <hr />

            <div class="platform" v-for="(platform, index) in fplatforms" :key="(platform, index)">
              <div class="row">
                <div class="col-md-6">
                  <h6>{{ platform.name }}</h6>
                </div>
                <div class="col-md-6">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" v-model="platform.status" />
                  </div>
                </div>
              </div>

              <div class="pltform_data" v-if="platform.status">
                <div class="row">
                  <div class="col-xl-2">
                    <label>ID</label>
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Platfrom ID" v-model="platform.id" disabled
                        required />
                    </div>
                  </div>

                  <div class="col-xl-2">
                    <label>Name</label>
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Platfrom Name" v-model="platform.name"
                        disabled required />
                    </div>
                  </div>

                  <div class="col-xl-2">
                    <label>Total Teams</label>
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Total Teams" v-model.number="platform.teams"
                        required />
                    </div>
                  </div>

                  <div class="col-xl-2">
                    <label>Sport ID</label>
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Sport ID" v-model="platform.sportid"
                        required />
                    </div>
                  </div>

                  <div class="col-xl-2">
                    <label>Max Min Player</label>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder="Max Player" v-model.number="platform.max"
                            required />
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder="Min Player" v-model.number="platform.min"
                            required />
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="col-xl-2">
                    <label>Default</label>
                    <div class="mb-3">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" v-model="platform.default" disabled />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" v-for="type in types" :key="type">
                  <div class="col-xl-4">
                    <label>Type Sortname</label>
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Type Sortname" v-model.number="type.sortname"
                        disabled required />
                    </div>
                  </div>

                  <div class="col-xl-4">
                    <label>Type Min</label>
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Type Min"
                        v-model.number="platform.types[type.sortname].min" required />
                    </div>
                  </div>

                  <div class="col-xl-4">
                    <label>Type Max</label>
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Type Max"
                        v-model.number="platform.types[type.sortname].max" required />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div class="row">
              <div class="col-xl-12" style="text-align:right;">
                <div class="button-group"></div>
                <button type="button" class="btn bg-gradient-secondary mb-0" style="margin-right :5px;"
                  @click="addtype">Add Player Types</button>

                <button v-if="(types.length >= 3)" type="button" class="btn bg-gradient-success mb-0"
                  style="margin-right :5px;" @click="setplatforms">Add Platfrom</button>

                <button type="submit" class="btn bg-gradient-info mb-0">
                  <span v-if="isnew">Save Sport</span>
                  <span v-else>Update Sport</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <SportsPreview :id="id" :name="name" :color="color" :status="status" :total="total" :min="min" :max="max"
        :types="types" :action="action" :position="position" :cc="cc" :vc="vc" />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import SportsPreview from "./Sports-Preview.vue";


export default {
  props: ["isnew"],
  components: { SportsPreview },
  data() {
    return {
      id: "",
      name: "",
      position: "",
      color: "#7DCE10",
      status: true,
      total: null,
      min: null,
      max: null,
      types: [],
      action: false,
      platforms: [],
      fplatforms: [],
      cc: null,
      vc: null,
      currentSport: null,
    };
  },
  async created() {
    var db = firebase.firestore();
    var path = db.collection("platforms").orderBy("position");
    this.platforms = await database.getalldocs(path);


    if (!this.isnew) {
      this.id = this.$route.params.id;

      var path = db.collection("sports").doc(this.id.toLowerCase());

      var sport = await database.getdoc(path);

      this.currentSport = sport;

      this.name = sport.name;
      this.position = sport.position;
      this.color = sport.color;
      this.status = sport.status;
      this.total = sport.total;
      this.min = sport.min;
      this.max = sport.max;
      this.types = sport.types;
      this.fplatforms = sport.platforms;
      this.cc = sport.cc;
      this.vc = sport.vc;
    }
  },
  methods: {
    addtype() {
      this.types.push({
        id: null,
        name: "",
        sortname: "",
        min: null,
        max: null,
      });
    },
    deletetype(index) {
      this.types.splice(index, 1);
    },
    savesport() {
      var selectedplatforms = [];

      this.fplatforms.forEach((platform) => {
        if (platform.status) {
          selectedplatforms.push(platform);
        }
      });

      var object = {
        id: this.id.toLowerCase(),
        name: this.name,
        color: this.color,
        total: this.total,
        min: this.min,
        max: this.max,
        position: this.position,
        status: this.status,
        types: this.types,
        platforms: selectedplatforms,
        cc: this.cc,
        vc: this.vc,
      };

      if (this.isnew) {
        database.savedoc(object, "sports", this.id.toLowerCase());
      } else {
        database.updatedoc(object, "sports", this.id.toLowerCase());
      }
    },
    setplatforms() {
      this.fplatforms = [];

      this.platforms.forEach((platform) => {

        var exsitPlatform = null;

        this.currentSport.platforms.forEach((cplatform) => {
          if (cplatform.id == platform.id) {
            exsitPlatform = cplatform;
          }
        });

        if (exsitPlatform) {
          this.fplatforms.push(exsitPlatform);
        } else {
          var intypes = {};

          this.types.forEach((type) => {
            intypes[type.sortname] = {
              min: null,
              max: null,
            };
          });

          this.fplatforms.push({
            id: platform.id,
            name: platform.name,
            default: platform.default,
            teams: null,
            sportid: null,
            types: intypes,
          });
        }



      });

    },
  },
};
</script>

<style scoped>
.form-switch .form-check-input,
.form-check .form-check-input {
  margin-left: 0 !important;
  float: none;
}
</style>