<template>
  <div class="table-responsive">
    <table class="table align-items-left mb-0">
      <thead>
        <tr>
          <th class="text-secondary opacity-7"></th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Dream11</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Myteam11</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">My11Circle</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Vision11</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Series ID</td>
          <td>
            <p class="text-xs font-weight-bold mb-0">{{ match.series.platforms.dream11.id }}</p>
          </td>
          <td>
            <p v-if="match.series.platforms.myteam11 != undefined" class="text-xs font-weight-bold mb-0">{{ match.series.platforms.myteam11.id }}</p>
          </td>
          <td>
            <p v-if="match.series.platforms.my11circle != undefined" class="text-xs font-weight-bold mb-0">{{ match.series.platforms.my11circle.id }}</p>
          </td>
          <td>
            <p v-if="match.series.platforms.vision11 != undefined" class="text-xs font-weight-bold mb-0">{{ match.series.platforms.vision11.id }}</p>
          </td>
        </tr>
        
        <tr>
          <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Match ID</td>
          <td>
            <p class="text-xs font-weight-bold mb-0">{{ match.platforms.dream11.id }}</p>
          </td>
          <td>
            <p v-if="match.platforms.myteam11 != undefined" class="text-xs font-weight-bold mb-0">{{ match.platforms.myteam11.id }}</p>
          </td>
          <td>
            <p v-if="match.platforms.my11circle != undefined" class="text-xs font-weight-bold mb-0">{{ match.platforms.my11circle.id }}</p>
          </td>
          <td>
            <p v-if="match.platforms.vision11 != undefined" class="text-xs font-weight-bold mb-0">{{ match.platforms.vision11.id }}</p>
          </td>
        </tr>
        <tr>
          <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Team 1 ID</td>
          <td>
            <p class="text-xs font-weight-bold mb-0">{{ match.teams[0].platforms.dream11.id }}</p>
          </td>
          <td>
            <p v-if="match.teams[0].platforms.myteam11 != undefined" class="text-xs font-weight-bold mb-0">{{ match.teams[0].platforms.myteam11.id }}</p>
          </td>
          <td>
            <p v-if="match.teams[0].platforms.my11circle != undefined" class="text-xs font-weight-bold mb-0">{{ match.teams[0].platforms.my11circle.id }}</p>
          </td>
          <td>
            <p v-if="match.teams[0].platforms.vision11 != undefined" class="text-xs font-weight-bold mb-0">{{ match.teams[0].platforms.vision11.id }}</p>
          </td>
        </tr>
        <tr>
          <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Team 2 ID</td>
          <td>
            <p class="text-xs font-weight-bold mb-0">{{ match.teams[1].platforms.dream11.id }}</p>
          </td>
          <td>
            <p v-if="match.teams[1].platforms.myteam11 != undefined" class="text-xs font-weight-bold mb-0">{{ match.teams[1].platforms.myteam11.id }}</p>
          </td>
          <td>
            <p v-if="match.teams[1].platforms.my11circle != undefined" class="text-xs font-weight-bold mb-0">{{ match.teams[1].platforms.my11circle.id }}</p>
          </td>
          <td>
            <p v-if="match.teams[1].platforms.vision11 != undefined" class="text-xs font-weight-bold mb-0">{{ match.teams[1].platforms.vision11.id }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["match"],
};
</script>

<style>
.table thead th {
  padding: 0.5rem 0.5rem;
}
</style>