<template>
    <div :class="{ 'modal fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"
        :id="request_success" tabindex="-1" role="dialog" :aria-labelledby="request_success" aria-hidden="true">
        <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title" id="modal-title-default">{{ title }}</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <img :src="image" alt="">
                </div>
                <div class="modal-footer">
                    <button @click="modalclose('toggelRequestImageModal', '', '')" type="button"
                        class="btn btn-link ml-auto mb-0" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div :class="{ 'modal-backdrop fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"></div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import swlalerts from "../../swlalerts";

export default {
    props: ["active", "image", "title"],
    data() {
        return {};
    },
    created() {
    },
    methods: {
        modalclose(funtext, ...params) {
            console.log(funtext, ...params);
            this.$emit(funtext, ...params);
        }

    },
};
</script>

<style scoped>
img {
    max-width: 100%;
}
</style>