<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-2">
          <img v-if="player.image == ''" src="../../../assets/img/default-player-image.png" alt />
          <img v-else :src="player.image" alt />
        </div>
        <div class="col-10">
          <h5 style="margin: 0;"> <b>{{ player.name }}</b> </h5>
          <h6 style="margin: 0 0 8px;">{{ player.sortName }}</h6>
        </div>
        <div class="col-12">
          <div class="table-responsive">
            <table class="table align-items-left mb-0">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Credits</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Points</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Selection Rate</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Team</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ player.credit }}</p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ player.points }}</p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ player.selected_by }}%</p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ player.teamcode }}</p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ player.short_role }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            type="button"
            class="btn bg-gradient-primary w-100 mb-0 mt-3"
            @click="funselcator('bottomv11')"
          >Send To Bottom</button>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn bg-gradient-danger w-100 mb-0 mt-3"
            @click="funselcator('deleteplayerv11')"
          >Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  props: ["player", "index"],
  data() {
    return {};
  },
  methods: {
    funselcator(funtext) {
      this.$emit(funtext, this.index);
    },
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
}
.table thead th {
  padding: 0.2rem 0.2rem;
}
</style>