<template>
  <!-- <nav class="navbar navbar-vertical mb-3 p-0">
    <ul class="navbar-nav">
      <li class="nav-item">
        <router-link class="nav-link" active-class="active" to="/rewardrequests/requests-all">All
          Rewards</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" active-class="active" to="/rewardrequests/requests-pending">Pending
          Rewards</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" active-class="active" to="/rewardrequests/requests-failed">Pending
          Failed</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" active-class="active" to="/rewardrequests/requests-success">Pending
          Success</router-link>
      </li>
    </ul>
  </nav> -->
  <router-view />
</template>

<script>
export default {
  data() {
    return {
    };
  },
  watch: {
  },
};
</script>


<style scoped>
.navbar {
  display: block;
  box-shadow: none;
}

.navbar-nav {
  flex-direction: row;
  flex-wrap: nowrap;
}

.navbar-nav .nav-item {
  width: 100%;
}

.navbar-nav .nav-item .nav-link {
  text-align: center;
  display: block;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
</style>