<template>
  <div class="card mb-3">
    <div class="card-body p-3">
      <div class="row">
        <div class="col">
          <label>Sheet Name</label>
          <div class="mb-3">
            <input type="text" class="form-control" placeholder="Sheet Name" v-model="name" required />
          </div>
        </div>

        <div class="col">
          <label>Select PG</label>
          <div class="mb-3">
            <select class="form-control" v-model="pg">
              <option value="">Select PG</option>
              <option value="paytm">Paytm</option>
              <option value="cashfree">Cashfree</option>
            </select>
          </div>
        </div>

        <div class="col">
          <textReader @load="csvUploaded($event)"></textReader>
        </div>
        <div class="col">
          <label>Download CSV</label>
          <div>
            <button type="button" class="btn bg-gradient-secondary w-100" @click="downloadSheet">Download</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-xl-3">
      <div class="card">
        <div class="card-body p-3">
          <div class="row">
            <div class="col-6">
              <div class="numbers">
                <p class="text-sm mb-0 text-capitalize font-weight-bold">PG Total Transaction</p>
                <h5 class="font-weight-bolder mb-0" :key="csvData">{{ csvData.length }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <div class="card">
        <div class="card-body p-3">
          <div class="row">
            <div class="col-6">
              <div class="numbers">
                <p class="text-sm mb-0 text-capitalize font-weight-bold">PG Total Payments</p>
                <h5 class="font-weight-bolder mb-0" :key="paytmTotal">{{ paytmTotal }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <div class="card">
        <div class="card-body p-3">
          <div class="row">
            <div class="col-6">
              <div class="numbers">
                <p class="text-sm mb-0 text-capitalize font-weight-bold">Total Transaction</p>
                <h5 class="font-weight-bolder mb-0" :key="allSubscription">{{ allSubscription.length }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <div class="card">
        <div class="card-body p-3">
          <div class="row">
            <div class="col-6">
              <div class="numbers">
                <p class="text-sm mb-0 text-capitalize font-weight-bold">Total Payments</p>
                <h5 class="font-weight-bolder mb-0" :key="total">{{ total }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body p-3">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">State</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Amount</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Commission</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">GST</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Settled Amount</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Order Id</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Refund</th>
            </tr>
          </thead>
          <tbody v-if="pg == 'paytm'">
            <tr v-for="(csvSubscription, index) in csvData" :key="(csvSubscription, index)">
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription.state }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription.planname }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription.Amount }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription.Commission }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription.GST }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription.Settled_Amount }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription.Order_ID.slice(1, -1) }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription.refundAmt }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-if="pg == 'cashfree'">
            <tr v-for="(csvSubscription, index) in csvData" :key="(csvSubscription, index)">
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription.state }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription.planname }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription.Amount }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription["Service Charge"] }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription["ST/GST"] }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription["Settlement Amount"] }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription["Order Id"] }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ csvSubscription["refundAmt"] }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

import textReader from "../../components/gst/fileReader.vue";

export default {
  components: { textReader },
  data() {
    return {
      name: "",
      pg: "",
      allSubscription: [],
      csvData: [],
      paytmTotal: 0,
      total: 0,
    };
  },
  methods: {
    async csvUploaded(text) {

      const db = firebase.firestore();

      if (this.pg == "paytm") {

        console.log(text);

        var delimiter = ",";

        const headers = text.slice(0, text.indexOf("\n")).split(delimiter);

        const rows = text.slice(text.indexOf("\n") + 1).split("\n");

        const arr = rows.map(function (row) {
          const values = row.split(delimiter);

          const el = headers.reduce(function (object, header, index) {
            object[header] = values[index];
            return object;
          }, {});

          return el;
        });



        this.csvData = [];
        this.csvData = arr;
        this.paytmTotal = 0;
        this.allSubscription = [];
        this.total = 0;

        var index = 0;

        for (const txnSingle of this.csvData) {

          if (txnSingle.Amount != undefined) {
            this.paytmTotal = parseInt(txnSingle.Amount) + this.paytmTotal;

            var OrderIDTemp = txnSingle.Order_ID.slice(1, -1);

            var path = db.collection("subscriptions").doc(OrderIDTemp);

            var SingleSubscription = await database.getdoc(path);

            if (SingleSubscription != undefined) {
              this.allSubscription.push(SingleSubscription);

              if (SingleSubscription.offer) {
                this.total = SingleSubscription.offeramount + this.total;
              } else {
                this.total = SingleSubscription.price + this.total;
              }

              this.csvData[index].state = SingleSubscription.state;
              this.csvData[index].planname = SingleSubscription.planname;

              if (SingleSubscription.refundAmt != 0) {
                this.csvData[index].refundAmt = SingleSubscription.refundAmt;
              } else {
                this.csvData[index].refundAmt = 0;
              }
            } else {
              this.csvData[index].state = "unknown";
              this.csvData[index].planname = "unknown";
              this.csvData[index].refundAmt = 0;
            }
          } else {
            this.csvData.splice(index, 1);
          }
          index++;
        }

        this.csvData.sort((b, a) => {
          if (a.state > b.state) return -1;
          if (a.state < b.state) return 1;
          return 0;
        });

      } else if (this.pg == "cashfree") {

        console.log(text); // Debugging के लिए

        var delimiter = ","; // CSV का डिलीमीटर

        // **Headers निकालें और Double Quotes हटाएँ**
        const headers = text
          .slice(0, text.indexOf("\n"))
          .split(delimiter)
          .map(h => h.trim().replace(/"/g, "")); // Double Quotes हटाए

        // **Rows निकालें और फ़िल्टर करें (empty lines remove)**
        const rows = text
          .slice(text.indexOf("\n") + 1)
          .split("\n")
          .filter(row => row.trim());

        // **CSV को JSON में कन्वर्ट करें**
        const arr = rows.map((row) => {
          const values = row.split(delimiter).map(v => v.trim().replace(/"/g, "")); // Double Quotes हटाए

          const el = headers.reduce((object, header, index) => {
            object[header] = values[index] ? values[index] : ""; // अगर कोई वैल्यू missing हो तो खाली string रखें
            return object;
          }, {});

          return el;
        });

        // **Processed Data सेट करें**
        this.csvData = arr;
        this.paytmTotal = 0;
        this.allSubscription = [];
        this.total = 0;

        var index = 0;




        for (const txnSingle of this.csvData) {

          console.log(txnSingle);

          if (txnSingle.Amount != undefined && txnSingle["Refunded"] != "Yes") {

            this.paytmTotal = parseInt(txnSingle.Amount) + this.paytmTotal;

            var OrderIDTemp = txnSingle["Order Id"];

            var path = db.collection("subscriptions").doc(OrderIDTemp);

            var SingleSubscription = await database.getdoc(path);

            if (SingleSubscription != undefined) {
              this.allSubscription.push(SingleSubscription);

              if (SingleSubscription.offer) {
                this.total = SingleSubscription.offeramount + this.total;
              } else {
                this.total = SingleSubscription.price + this.total;
              }

              this.csvData[index].state = SingleSubscription.state;
              this.csvData[index].planname = SingleSubscription.planname;

              if (SingleSubscription.refundAmt != 0) {
                this.csvData[index].refundAmt = SingleSubscription.refundAmt;
              } else {
                this.csvData[index].refundAmt = 0;
              }
            } else {
              this.csvData[index].state = "unknown";
              this.csvData[index].planname = "unknown";
              this.csvData[index].refundAmt = 0;
            }
          } else {
            this.csvData.splice(index, 1);
          }
          index++;
        }

        this.csvData.sort((b, a) => {
          if (a.state > b.state) return -1;
          if (a.state < b.state) return 1;
          return 0;
        });









      }




    },



    async downloadSheet() {
      var html = document.querySelector("table").outerHTML;
      this.htmlToCSV(html, this.name + ".csv");
    },

    htmlToCSV(html, filename) {
      var data = [];
      var rows = document.querySelectorAll("table tr");

      for (var i = 0; i < rows.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll("td, th");

        for (var j = 0; j < cols.length; j++) {
          row.push(cols[j].innerText);
        }

        data.push(row.join(","));
      }

      this.downloadCSVFile(data.join("\n"), filename);
    },

    downloadCSVFile(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;

      download_link.href = window.URL.createObjectURL(csv_file);

      download_link.style.display = "none";

      document.body.appendChild(download_link);

      download_link.click();
    },
  },
};
</script>

<style></style>
